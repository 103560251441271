import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';

import LoadingScreen from 'Components/ui/LoadingScreen';

import { getRefreshToken as fetchRefreshToken, getTokenMetadata } from 'Api/auth';
import {
  getRefreshToken,
  isLoading,
  isAuthenticated,
  isExpired,
  authStore,
  setAuthStore,
  setUserId,
  logout,
  setExpirationDate,
} from 'Graphql/store';

const AuthContext = ({ children }) => {
  const reactiveAuthStore = useReactiveVar(authStore);
  const authToken = getRefreshToken();
  const [localLoading, setLocalLoading] = useState(isLoading());

  const handleSuccessfulRefresh = ({ data }) => {
    setAuthStore(data);
    setLocalLoading(false);
  };

  const handleError = () => {
    setLocalLoading(false);
    logout();
  };

  const refreshQuery = async (currentRefreshToken) => {
    return fetchRefreshToken(
      { refresh_token: currentRefreshToken },
      { onSuccess: handleSuccessfulRefresh, onError: handleError },
    );
  };

  const refresh = useCallback(
    async (refreshToken) => {
      setExpirationDate();
      const { data: authData } = await refreshQuery(refreshToken);
      const { access_token: accessToken } = authData || {};
      if (!reactiveAuthStore?.userId)
        await getTokenMetadata(accessToken, { onSuccess: ({ data }) => setUserId(data?.user_id) });
    },
    [refreshQuery, reactiveAuthStore?.userId],
  );

  useEffect(() => {
    if (isAuthenticated() && authToken && (!reactiveAuthStore?.accessToken || isExpired())) {
      setLocalLoading(true);
      refresh(authToken);
    }
  }, [authToken, reactiveAuthStore?.accessToken, isExpired()]);

  return <div>{localLoading ? <LoadingScreen fullScreen size="big" /> : children}</div>;
};

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
